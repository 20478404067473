import React, { useEffect } from 'react'
import Navbar from '../Navbar.jsx';
import Header from '../Header.jsx'
import AboutUsMain from './AboutUsMain.jsx';
import AboutUsContact from './AboutUsContact.jsx'
import Footer from '../Footer.jsx';

const AboutUs = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
      },[])

    return (
        <div>
            <div className='bg-fixed bg-center z-1 bg-white bg-gradient-to-r from-[#081d2b] via-[#163f6c] to-[#758da7]'>
                <Navbar />
                <Header title={["ABOUT ", <span className='whitespace-normal'>MCMILLAN-MCGEE</span>]} bgImage="bg-[url('./assets/AboutUs/AboutUsBannerBackground.jpg')]" titleClasses="lg:text-6xl md:text-5xl text-4xl xl:pt-[26rem] lg:pt-72 pt-32 xl:pb-28 lg:pb-24 pb-12 xl:pl-24 lg:pl-20 pl-8 bg-gradient-to-t from-[#01111c]" subTitleClasses="hidden"/>
                <AboutUsMain />
                <AboutUsContact />
                <Footer/>
            </div>
        </div>
    );
}

export default AboutUs