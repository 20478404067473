import React from 'react'

const LandingStats = (props) => {
  return (
    <div>
        <div className='col-span-1 my-4 lg:mx-8'>
            <h1 className='lg:text-center xl:text-6xl lg:text-5xl md:text-4xl text-2xl calibri-600'>{ props.stat }</h1>
            <p className='lg:text-center lg:mx-12 my-4 lg:text-xl text-lg whitespace-normal md:whitespace-nowrap'>{ props.description }</p>
            <hr className='w-[60%] lg:mx-auto lg:my-12 border border-[#8eceeb]'/>
        </div>
    </div>
  )
}

export default LandingStats