const LandingBanner = () => {
    return (
        <div className='w-full h-screen calibri-300'>
            <div dangerouslySetInnerHTML={{ __html: `<video style='	position: relative; width: 100%; height: 100vh; object-fit: cover; z-index: 0;' src="${require('../../assets/MainBannerBackground.mp4')}" playsinline autoPlay loop muted autobuffer/>` }}></div>
            <div className="absolute top-0 w-full h-screen flex text-white bg-gradient-to-t from-[#0f3550] z-2">
                <div className="ml-12 mr-8 mt-16 w-full mx-auto flex flex-col justify-center">
                    <h1 className="lg:text-8xl md:text-6xl text-5xl drop-shadow-xl calibri-600">Advanced</h1>
                    <h1 className="lg:text-8xl md:text-6xl text-5xl drop-shadow-xl lg:pb-4 calibri-400">Technologies for</h1>
                    <h1 className="lg:text-8xl md:text-6xl text-5xl drop-shadow-xl calibri-600">Advanced</h1>
                    <h1 className="lg:text-8xl md:text-6xl text-5xl drop-shadow-xl lg:pb-4 calibri-400">Challenges.</h1>
                    <div className='py-8'>
                        <p className="w-100 lg:text-2xl text-xl drop-shadow-xl">Global Technology Company Plugged into <br/> the Rapidly Growing Remediation Industry.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingBanner