import React from 'react'
import { useNavigate } from 'react-router-dom';
import ContactUsLocationsHQ from '../../assets/ContactUs/ContactUsLocationsHQ.png'
import ContactUsLocationsUSA from '../../assets/ContactUs/ContactUsLocationsUSA.png'
import ContactUsLocationsEurope from '../../assets/ContactUs/ContactUsLocationsEurope.png'
import ContactUsLocation from './ContactUsLocation';
import ContactUsForm from './ContactUsForm'

const AboutUsMain = () => {
  const navigate = useNavigate();

  return (
    <div>
        {/* Contact Us and Join Our Team section large screen*/}
        <div className='hidden xl:grid grid-cols-2 py-24'>
            <div className='col-span-1 pr-24 calibri-400'>
                <h1 className='text-white lg:text-5xl md:text-3xl text-lg mx-12 my-12'>CONTACT US</h1>
                <p className='text-white my-12 lg:text-4xl md:text-2xl text-base mx-12'>Connect with a Mc&#178; expert to discover the possiblities of our advanced technologies.</p>
                <p className='text-[#92d3f0] my-12 lg:text-4xl md:text-2xl text-base mx-12'>Schedule a discovery call today.</p>
                <h1 className='text-white lg:text-5xl md:text-3xl text-lg mt-24 mx-12 mb-12'>JOIN OUR TEAM</h1>
                <p className='text-white my-12 lg:text-4xl md:text-2xl text-base mx-12'>Join our team and become part of the future of the thermal remediation industry.</p>
                <div className='flex justify-end'>
                    <button className='w-60  text-white p-4 rounded-full hover:bg-[#758da8] border-4 border-[#8ccae8] lg:text-2xl text-xl' onClick={() => navigate('/Careers')}>CAREERS</button>
                </div>
            </div>
            <div className='col-span-1 mx-12'>
                <ContactUsForm 
                    extraClasses={{
                        isPopUp: '',
                        isClosable: false,
                        formStyling: ''
                    }}
                />
            </div>
        </div>
        {/* Contact Us and Join Our Team section smaller screen*/}
        <div className='xl:hidden block'>
            <div className='calibri-400'>
                <h1 className='text-white lg:text-5xl md:text-3xl text-lg my-12 mx-12'>CONTACT US</h1>
                <p className='text-white my-12 mx-12 lg:text-4xl md:text-2xl text-base text-xl'>Connect with a Mc&#178; expert to discover the possiblities of our advanced technologies.</p>
                <p className='text-[#92d3f0] my-12 mx-12 lg:text-4xl md:text-2xl text-base text-xl'>Schedule a discovery call today.</p>
                <ContactUsForm 
                    extraClasses={{
                        isPopUp: '',
                        isClosable: false,
                        formStyling: ''
                    }}
                />
                <h1 className='text-white lg:text-5xl md:text-3xl text-lg xl:mt-24 mt-12 mb-12 mx-12'>JOIN OUR TEAM</h1>
                <p className='text-white my-12 lg:text-4xl md:text-2xl text-base mx-12'>Join our team and become part of the future of the thermal remediation industry.</p>
                <div className='flex justify-end'>
                    <button className='w-60  text-white p-4 rounded-full hover:bg-[#758da8] border-4 border-[#8ccae8] lg:text-2xl text-xl mr-12' onClick={() => navigate('/Careers')}>CAREERS</button>
                </div>
            </div>    
        </div>
        <div className='contact-us-location-gradient-background'>
            {/* McMillan-McGee Locations */}
            <div className='xl:grid xl:grid-cols-3 xl:gap-8 place-content-evenly xl:pb-24 xl:px-24 xl:mt-0 pb-12 px-12 mt-12 items-stretch'>
                <ContactUsLocation 
                    titleImg={ContactUsLocationsHQ} 
                    location="CALGARY, CANADA" 
                    address="4895 35B ST SE, CALGARY AB T2B 3M9, CANADA" 
                    emailGeneral="General: info@mcmillan-mcgee.com"
                    emailMedia="Media: media@mcmillan-mcgee.com"
                    phone="+1 403 569 5100"
                    fax="+1 403 272 7201 (Fax)"
                    mapLink="https://shorturl.at/hg34X"
                />
                <ContactUsLocation 
                    titleImg={ContactUsLocationsUSA}  
                    location="DUPO, ILLINOIS" 
                    address="391B TRANSPOINT DR, DUPO, IL 62239, UNITED STATES" 
                    emailGeneral="General: info@mcmillan-mcgee.com"
                    emailMedia="Media: media@mcmillan-mcgee.com"
                    mapLink="https://shorturl.at/KhjA7"
                />
                <ContactUsLocation 
                    titleImg={ContactUsLocationsEurope}  
                    location="ZELZATE, BELGIUM" 
                    address="INDUSTRIEPARK ROSTEYNE 1, ZELZATE, 9060, BE" 
                    emailGeneral="General: info@mcmillan-mcgee.com"
                    emailMedia="Media: media@mcmillan-mcgee.com"
                    mapLink="https://shorturl.at/wMIOi"
                />
            </div>
        </div>
    </div>
  )
}

export default AboutUsMain