import React from 'react'
import classNames from 'classnames';

const Header = (props) => {
  return (
    <div>
        <div className='flex pt-18'>
            <div className={classNames('w-full h-full mx-auto border-t-2 border-b-2 border-white bg-cover object-cover bg-right p-0', props.bgImage)}>
                <h1 className={classNames('calibri-600 text-white drop-shadow-lg', props.titleClasses)}>{ props.title }</h1>
                <p className={classNames('text-center lg:text-xl md:text-lg text-base orbitron-400 text-white drop-shadow-lg', props.subTitleClasses)}>{ props.subTitle }</p>
            </div>
        </div>
    </div>
  )
}

export default Header