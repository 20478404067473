import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CareersForm = ({ onClose, extraClasses }) => {
  const [emailForm, setEmailForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    location: '',
    phoneNumber: '',
    jobTitle: '',
    field: '',
    level: '',
    subject: '',
    message: '',
    purpose: ''
  });

  CareersForm.propTypes = {
    onClose: PropTypes.any,
    extraClasses: PropTypes.shape({
      isPopUp: PropTypes.string,
      isClosable: PropTypes.bool,
      formStyling: PropTypes.string
    })
  }

  CareersForm.defaultProps = {
    extraClasses: {
      isPopUp: '',
      isClosable: '',
      formStyling: ''
    }
  }

  //Result if message was sent or not
  const [result, setResult] = useState('');

  //Status of while message is being sent
  const [status, setStatus] = useState('Submit');

  // Used to reset form back to black inputs
  function resetEmailForm() {
    setEmailForm({ 
      firstName: '',
      lastName: '',
      email: '',
      location: '',
      phoneNumber: '',
      jobTitle: '',
      field: '',
      level: '',
      subject: '',
      message: '',
      purpose: ''
    });
  }

  // Handle form input updating js variables 
  function handleEmailFormChange(event) {
    setEmailForm((prevEmailData) => {
        return {
            ...prevEmailData,
            [event.target.name]: event.target.value,
        };
    });

    // Set result variable
    if (result.length > 0) {
        setResult('');
    }
  }

  // Handle submission of form and sending API request
  const handleSubmit = async (e) => {
    setResult('');
    e.preventDefault();
    setStatus('Sending...');

    const { firstName, lastName, email, location, phoneNumber, jobTitle, field, level, subject, message, purpose } = e.target.elements;

    let emailBody = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      location: location.value,
      phoneNumber: phoneNumber.value,
      jobTitle: jobTitle.value,
      field: field.value,
      level: level.value,
      subject: subject.value,
      message: message.value,
      purpose: purpose.value
    };

    // Send email using your preferred method (e.g., via API)
    try {
      // const response = await fetch('http://localhost:8080/send', { // If on localhost
      const response = await fetch('/send.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(emailBody)
      })

      setStatus('Submit');
      let result = await response.json();

      if (result.status === 'success') {
        setResult('Message Sent!');
        resetEmailForm();
      } else if (result.status === 'fail') {
        alert(result.message);
      }
    } catch (error) {
        console.error(error);
        setStatus('Submit');
        setResult('Uh oh! Issues with submitting message.');
    }
  };

  return (
    <div className={classNames('calibri-300', extraClasses.isPopUp)}>
        <form onSubmit={handleSubmit} method='POST' className={classNames('bg-white duration-300 shadow-xl relative rounded px-8 pt-6 pb-8 lg:mx-8 mx-6 my-4', extraClasses.formStyling)}>
          <input type='hidden' id='purpose' value='career'/>
          <div className="flex justify-center">
              <button className='absolute text-[#f75f00] bg-gray-100 text-lg top-[-1rem] rounded-xl shadow-lg px-2 disabled'>@mcmillan-mcgee.com</button>
          </div>
          <div className='flex justify-between my-4'>
            <h1 className='lg:text-4xl text-xl font-bold text-gray-700 py-2 whitespace-normal md:whitespace-nowrap'>Get in Touch with Mc<span>&#178;</span></h1>
            {extraClasses.isClosable ? <button className="bg-[#e8f0ff] hover:bg-[#ebf1fc] hover:scale-110 w-[50px] text-black font-semibold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline" onClick={onClose}>X</button> : null}
          </div>

          {/* Large screen */}
            {/* This row includes inputs for First Name, Last Name, Email */}
          <div className='block lg:flex lg:space-x-4'>
            <div className="w-full lg:w-1/2 my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="firstName">First Name</label>
              <input
                  className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="firstName"
                  type="text"
                  name="firstName"
                  value={emailForm.firstName}
                  onChange={handleEmailFormChange}
                  placeholder='Required'
                  required
              />
            </div>
            <div className="w-full lg:w-1/2 my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="lastName">Last Name</label>
              <input
                  className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="lastName"
                  type="text"
                  name="lastName"
                  value={emailForm.lastName}
                  onChange={handleEmailFormChange}
                  placeholder='Required'
                  required
              />
            </div>
          </div>
          {/* This row includes inputs for Email */}
          <div className="w-full my-4">
            <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="email">Email</label>
            <input
                className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                name="email"
                value={emailForm.email}
                onChange={handleEmailFormChange}
                placeholder='Required'
                required
            />
          </div>
          {/* This row includes inputs for Location and Phone Number */}
          <div className='lg:flex lg:space-x-4'>
            <div className="w-full lg:w-1/2 my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="location">Location</label>
              <input
                  className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="location"
                  type="text"
                  name="location"
                  value={emailForm.location}
                  onChange={handleEmailFormChange}
              />
            </div>
            <div className="w-full lg:w-1/2 my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="phoneNumber">Phone Number</label>
              <input
                  className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="phoneNumber"
                  type="text"
                  name="phoneNumber"
                  value={emailForm.phoneNumber}
                  onChange={handleEmailFormChange}
              />
            </div>
          </div>
          {/* This row includes inputs for Job Title, Field, Level */}
          <div className='block lg:flex lg:space-x-4'>
            <div className="w-full lg:w-1/3 my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="jobTitle">Job Title</label>
              <select
                className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="jobTitle"
                name="jobTitle"
                value={emailForm.jobTitle}
                onChange={handleEmailFormChange}
              >
                <option value="" disabled></option>
                <option value="Field/ Operations">Field/ Operations</option>
                <option value="Corporate">Corporate</option>
                <option value="Information Technology">Information Technology</option>
                <option value="Manager">Manager</option>
                <option value="Upper Management">Upper Management</option>
              </select>
            </div>
            <div className="w-full lg:w-1/3 my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="field">Field</label>
              <select
                className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="field"
                name="field"
                value={emailForm.field}
                onChange={handleEmailFormChange}
              >
                <option value="" disabled></option>
                <option value="Engineer">Engineer</option>
                <option value="Technician">Technician</option>
                <option value="Business Development">Business Development</option>
                <option value="Media">Media</option>
                <option value="Research">Research</option>
              </select>
            </div>
            <div className="w-full lg:w-1/3 my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="level">Level</label>
              <select
                className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="level"
                name="level"
                value={emailForm.level}
                onChange={handleEmailFormChange}
              >
                <option value="" disabled></option>
                <option value="Senior">Senior</option>
                <option value="Staff">Staff</option>
                <option value="Professional Engineer">Professional Engineer</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Certified">Certified</option>
                <option value="Apprentice">Apprentice</option>
                <option value="Entry-level">Entry-level</option>
                <option value="Engineer-In-Training">Engineer-In-Training</option>
                <option value="Student/ Intern/ Co-op">Student/ Intern/ Co-op</option>
              </select>
            </div>
          </div>

          {/* Small screen */}
          {/* <div className='lg:hidden block'> */}
            {/* This row includes inputs for First Name and Last Name */}
            {/* <div className="w-full my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="firstName">First Name</label>
              <input
                  className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="firstName"
                  type="text"
                  name="firstName"
                  value={emailForm.firstName}
                  onChange={handleEmailFormChange}
              />
            </div>
            <div className="w-full my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="lastName">Last Name</label>
              <input
                  className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="lastName"
                  type="text"
                  name="lastName"
                  value={emailForm.lastName}
                  onChange={handleEmailFormChange}
              />
            </div>
            <div className="w-full my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="email">Email</label>
              <input
                  className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  name="email"
                  value={emailForm.email}
                  onChange={handleEmailFormChange}
              />
            </div>
            <div className="w-full my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="location">Location</label>
              <input
                  className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="location"
                  type="text"
                  name="location"
                  value={emailForm.location}
                  onChange={handleEmailFormChange}
              />
            </div>
            <div className="w-full my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="phoneNumber">Phone Number</label>
              <input
                  className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="phoneNumber"
                  type="text"
                  name="phoneNumber"
                  value={emailForm.phoneNumber}
                  onChange={handleEmailFormChange}
              />
            </div>
            <div className="w-full my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="jobTitle">Job Title</label>
              <select
                className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="jobTitle"
                name="jobTitle"
                value={emailForm.jobTitle}
                onChange={handleEmailFormChange}
              >
                <option value="" disabled></option>
                <option value="Manager">Manager</option>
                <option value="Upper Management">Upper Management</option>
                <option value="Engineer-In-Training">Engineer-In-Training</option>
                <option value="Information Technology">Information Technology</option>
                <option value="Apprentice">Apprentice</option>
              </select>
            </div>
            <div className="w-full my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="field">Field</label>
              <select
                className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="field"
                name="field"
                value={emailForm.field}
                onChange={handleEmailFormChange}
              >
                <option value="" disabled></option>
                <option value="Engineer">Engineer</option>
                <option value="Technician">Technician</option>
                <option value="Business Development">Business Development</option>
                <option value="Media">Media</option>
                <option value="Research">Research</option>
              </select>
            </div>
            <div className="w-full my-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="level">Level</label>
              <select
                className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="level"
                name="level"
                value={emailForm.level}
                onChange={handleEmailFormChange}
              >
                <option value="" disabled></option>
                <option value="Senior">Senior</option>
                <option value="Staff">Staff</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Entry-level">Entry-level</option>
                <option value="Student/ Intern/ Co-op">Student/ Intern/ Co-op</option>
              </select>
            </div>
          </div> */}
          
          <div className="mb-6">
            <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="subject">Subject</label>
            <input
                className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="subject"
                type="text"
                name="subject"
                value={emailForm.subject}
                onChange={handleEmailFormChange}
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="message">Message</label>
            <textarea
                className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full h-[8rem] py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                id="message"
                type="text"
                placeholder="(Max 300 characters)"
                name="message"
                value={emailForm.message}
                onChange={handleEmailFormChange}
            />
          </div>
          <div className='flex justify-center'>
              <button className="bg-[#6197fa] hover:bg-[#367af7] w-[150px] text-black font-semibold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline" type="submit">{status}</button>
          </div>
          <div className='flex justify-center'>
            <h3 className='block text-gray-700 text-md font-bold mb-2'>{result}</h3>
          </div>
        </form>
    </div>
  );
};

export default CareersForm;
