import React from 'react'
import IT_DSPBruce from '../../assets/IT-DSP/IT-DSPBruce.jpg'
import IT_DSPCoilDiagram from '../../assets/IT-DSP/IT-DSPCoilDiagram.png'
import IT_DSPComponents from '../../assets/IT-DSP/IT-DSPComponents.jpg'
import IT_DSPAdvantage from '../../assets/IT-DSP/IT-DSPAdvantage.png'
import IT_DSPAdvantagePic from '../../assets/IT-DSP/IT-DSPAdvantagePic.jpg'


const ET_DSPMain = () => {
  return (
    <div className='text-white calibri-300'>
        <div className=''>
            <h1 className='text-start text-white xl:text-5xl md:text-4xl text-2xl calibri-400 pt-24 xl:mx-48 lg:mx-24 mx-12'><span className='text-[#8ac7e2]'>CONTROLLED</span> THERMAL CONDUCTIVE HEATING</h1>
        </div>
        <div className='xl:grid xl:grid-cols-2 xl:gap-48 xl:px-48 lg:px-24 px-12  xl:py-16 py-12 bg-gradient-to-t from-black'>
            <div className='col-span-1'>
                <p className='lg:text-lg text-md'>Inductive-Thermal Dynamic Stripping Process&trade;</p>
                <p className='lg:text-lg text-md text-[#8ac7e2] mb-12'><i>In-situ thermal remediation technology.</i></p>
                <p className='text-justify lg:text-lg text-md mb-12'>Inductive Thermal-Dynamic Stripping Process (IT-DSP&trade;) is an emerging technology that utiilizes <span className='text-[#8ac7e2]'>electromagnetic induction</span> to generate heat directly <span className='text-[#8ac7e2]'>within the subsurface casing</span>. IT-DSP&trade; offers improved control and productivity at various depths, unlike traditional Thermal Conduction Heating (TCH) or In Situ Thermal Desorption (ISTD) methods, which rely on conventional electric or gas fired heating elements which are affected by heat transfer within the wellbore.</p>
            </div>
            <div className='col-span-1 flex justify-center xl:mt-0 mt-8'>
                <img className='h-full' src={IT_DSPBruce} alt='IT-DSP Bruce' />
            </div>
        </div>
        <div className='xl:grid xl:grid-cols-10 xl:gap-12 xl:px-48 lg:px-24 px-12 xl:py-16 py-12 bg-black'>
            <div className='col-span-4 py-auto'>
                <h1 className='text-white lg:text-5xl md:text-4xl text-3xl calibri-500 mx-auto mb-12'>COMPONENTS</h1>
                <p className='text-justify lg:text-lg text-md mb-8'>IT-DSP&trade; uses computer-controlled high-frequency electromagnetic fields for precise heating, minimizing radiant heat transfer and maximizing energy efficiency. This electromagnetic induction process generates heat in casings buried in the subsurface. Key components includes a <span className='text-[#7fbbd7]'>high-frequency, high-power inverter</span> and a <span className='text-[#7fbbd7]'>work coil</span> integrated into the casing, combined technical precision with energy optomization for advanced subsurface remediation.</p>
            </div>
            <hr className='xl:block hidden col-span-1 flex my-auto mx-auto vertical-line'/>
            <hr className='xl:hidden block w-3/5'/>
            <div className='col-span-5 flex justify-center xl:mt-0 mt-8'>
                <img className='h-full rounded-xl' src={IT_DSPComponents} alt='ET-DSP Water Components' />
            </div>
        </div>
        <div className='bg-gradient-to-b from-black'>
            <h1 className='text-white lg:text-4xl md:text-3xl text-2xl calibri-500 xl:mx-48 lg:mx-24 mx-12 pt-8'>IT-DSP&trade; WORK COIL</h1>
            <div className='xl:grid xl:grid-cols-2 xl:gap-24 xl:mx-48 lg:mx-24 mx-12 xl:py-16 pb-12'>
                <div className='col-span-1 flex justify-center xl:mt-0 mt-8'>
                    <img className='h-[90%]' src={IT_DSPCoilDiagram} alt='IT-DSP Work Coil Diagram' />
                </div> 
                <div className='col-span-1 xl:pr-12 pr-0'>
                    <h1 className='text-[#7fbbd7] lg:text-5xl md:text-4xl text-3xl calibri-500 mx-auto my-12'>Patented and Proven.</h1>
                    <p className='text-justify lg:text-lg text-md mb-8 h-full'>The IT-DSP&trade; system efficiently eliminates high-boiling-point compounds, achieving removal rates exceeding 99.9%. This advanced remediation technology offers an effective solution for addressing <span className='text-[#7fbbd7]'>PFAS</span> contamination, with removal efficiences exceeding 99%, even for contaminants requiring temperatures over 350&deg;C. IT-DSP&trade; utilizes computer-controlled high-frequency electromagnetic fields for precise heating regulation, minimizing radiant heat transfer and maximizing energy efficiency through this optimization.</p>
                </div>  
            </div>
        </div>
        <div>
            <div className='xl:grid xl:grid-cols-2 xl:gap-24 xl:px-48 lg:px-24 px-12 xl:py-16 pb-12'>
                <div className='col-span-1'>
                    <img className='xl:mx-0 rounded-xl' src={IT_DSPAdvantagePic} alt="IT-DSP Advantage" />
                </div>
                <div className='col-span-1 xl:pt-0 pt-16'>
                    <h1 className='text-white md:text-5xl text-3xl calibri-500 xl:mb-12 mb-8'>IT-DSP&trade; ADVANTAGE</h1>
                    <img src={IT_DSPAdvantage} alt='IT-DSP Advantage' />
                </div> 
            </div>
        </div>
    </div>
  )
}

export default ET_DSPMain