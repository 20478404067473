import React from 'react'
import Bruce from '../../assets/Headshots/Dr. Bruce.jpg'
import Brent from '../../assets/Headshots/Brent.jpg'
import Clayton from '../../assets/Headshots/Clayton.jpg'
import David from '../../assets/Headshots/David.jpg'
import Kristof from '../../assets/Headshots/Kristof.jpg'
import TeamCard from './TeamCard'

const TeamMain = () => {
  return (
    <div>
        <div className='w-full h-full text-center xl:py-28 xl:px-36 p-16 px-24 bg-gradient-to-t from-black via-black to-[#0d2442]'>
            <p className='text-justify text-white calibri-300 lg:text-2xl md:text-xl text-lg'>At Mc&#178;, our team is the foundation of our success. Together we do the math to deliver successful results, and continuously innovate to stay ahead. With a diverse range of experiences, achievements, and backgrounds, we are bold thinkers who provide valuable insights and expertise.</p>
            <p className='text-left text-white calibri-500 text-bold my-12 lg:text-2xl md:text-xl text-lg'>Get to know the talented Mc&#178; team.</p>
        </div>
        <div className='block xl:grid xl:grid-cols-3 xl:gap-10 place-content-around w-full h-full xl:pl-24 px-12 xl:pb-24 pb-12 bg-gradient-to-b from-black via-black to-[#09172b]'>
            <div className='flex col-span-1'>
                <hr className='xl:w-full w-40 my-auto mr-12'/>
                <h1 className='text-white xl:my-auto my-12 pr-12 calibri-400 xl:text-5xl md:text-3xl text-xl'>Leadership</h1>
            </div>
            <TeamCard img={Bruce} imgAlt="Dr.Bruce McGee" firstName="DR. BRUCE" lastName="McGEE" education="CD, Ph.D, P.Eng." position="President" subPosition="Chief Executive Officer"/>
            <TeamCard img={Brent} imgAlt="Brent Winder" firstName="BRENT" lastName="WINDER" education="B. S. Sc, MBA" position="Vice President" subPosition="Chief Operations Officer"/>
            <TeamCard img={Kristof} imgAlt="Kristof Van Vooren" firstName="KRISTOF" lastName="VAN VOOREN" education="MBA" position="Chief Financial Officer"/>
            <TeamCard img={David} imgAlt="David Rountree" firstName="DAVID" lastName="ROUNTREE" education="B. S, M. S, P. E, P. Eng. " position="President Mc2 USA" subPosition="Chief Engineer"/>
            <TeamCard img={Clayton} imgAlt="Clayton Campbell" firstName="CLAYTON" lastName="CAMPBELL" education="P. Eng." position="Vice President of Engineering"/>
        </div>
    </div>
  )
}

export default TeamMain