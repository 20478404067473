import React from 'react'
import CareersForm from './CareersForm'

const CareersMain = () => {
  return (
    <div>
        {/* Contact Us and Join Our Team section large screen */}
        <div className='hidden xl:grid xl:grid-cols-2 pl-24 py-24'>
            <div className='col-span-1 pr-24 calibri-400'>
                <h1 className='text-white lg:text-5xl md:text-4xl my-12'>WORK WITH US</h1>
                <p className='text-white my-12 lg:text-3xl md:text-2xl text-xl'>Join the dynamic team at Mc&#178; and shape the future with innovative solutions in a collaborative and technical environment.</p>
                <p className='text-[#92d3f0] my-12 lg:text-3xl md:text-2xl text-xl'>Introduce yourself to Mc&#178;</p>
            </div>
            <div className='col-span-1 mx-12'>
                <CareersForm 
                    extraClasses={{
                        isPopUp: '',
                        isClosable: false,
                        formStyling: ''
                    }}
                />
            </div>
        </div>
        {/* Contact Us and Join Our Team section small screen */}
        <div className='block xl:hidden xl:py-24 py-12'>
            <div className='pr-24 calibri-400 xl:pl-24 p-12'>
                <h1 className='text-white lg:text-5xl text-4xl my-12'>WORK WITH US</h1>
                <p className='text-white my-12 lg:text-3xl md:text-2xl text-xl'>Join the dynamic team at Mc&#178; and shape the future with innovative solutions in a collaborative and technical environment.</p>
                <p className='text-[#92d3f0] my-12 lg:text-3xl md:text-2xl text-xl'>Introduce yourself to Mc&#178;</p>
            </div>
            <div>
                <CareersForm 
                    extraClasses={{
                        isPopUp: '',
                        isClosable: false,
                        formStyling: ''
                    }}
                />
            </div>
        </div>
    </div>
  )
}

export default CareersMain