import React from 'react'
import { useNavigate } from 'react-router-dom';


const AboutUsContact = () => {
  const navigate = useNavigate();

  return (
    // about-us-contact-gradient-background is a custom class, can be found in index.css
    <div className="block lg:grid lg:grid-cols-2 py-12 xl:px-24 px-12 about-us-contact-gradient-background">
        <div className='lg:col-span-1'>
            <h1 className='calibri-600 text-white lg:text-3xl text-2xl my-12'>CONTACT US</h1>
            <p className='text-white calibri-300 my-12 lg:text-2xl md:text-xl text-lg'>Connect with a Mc&#178; expert to discover the possiblities of our advanced technologies.</p>
            <div className='flex justify-end'>
                <button className='w-60  text-white p-4 rounded-full hover:bg-[#758da8] border-4 border-[#8ccae8] calibri-500 lg:text-2xl text-xl' onClick={() => navigate('/ContactUs')}>INQUIRE</button>
            </div>
            <h1 className='calibri-600 text-white lg:text-3xl text-2xl my-12'>JOIN OUR TEAM</h1>
            <p className='text-white calibri-300 my-12 lg:text-2xl md:text-xl sm:text-lg'>Join our team and become part of the future of the thermal remediation industry.</p>
            <div className='flex justify-end'>
                <button className='w-60  text-white p-4 rounded-full hover:bg-[#758da8] border-4 border-[#8ccae8] calibri-500 lg:text-2xl text-xl' onClick={() => navigate('/Careers')}>CAREERS</button>
            </div>
        </div>
    </div>
  )
}

export default AboutUsContact