import React from 'react'
import ITDSPCoil from '../../assets/IT-DSP/IT-DSP Coil.png'
import Electrode from '../../assets/ET-DSP/Electrode.png'
import ProjectsMap from '../../assets/ProjectsMap.png'
import LandingStats from './LandingStats'
import { useNavigate } from 'react-router-dom';


const LandingMain = () => {
    const navigate = useNavigate();

    return (
            <div className="text-white calibri-300 w-full z-50">
                {/* First part with mission statement and such */}
                <div className='bg-gradient-to-b from-[#0f3550]'>
                    <h1 className='text-center text-[#7fbbd7] lg:text-5xl md:text-4xl text-xl calibri-400 mx-auto px-4 pt-24'>Blazing Trails in In-Situ Remediation</h1>
                    <p className='text-justify text-white lg:text-3xl md:text-2xl text-xl calibri-300 lg:mx-36 md:mx-24 mx-12 mt-12'>McMillan-McGee is committed to engineering solutions for the world's most complex environmental challenges. Specializing in in-situ thermal remediation, we consistently lead with the most advanced technical approaches as an innovative engineering and technology firm.</p>
                    <p className='text-center text-white lg:text-3xl md:text-2xl text-lg calibri-300 my-12'>Motivated by solutions, we lead the way forward.</p>
                </div>
                <hr className='w-[80%] mx-auto my-20 border border-[#8eceeb]'/>
                {/* 4 paragraphs to describe "Science-focused and future driven." */}
                <div className='my-16 mx-16'>
                    <div className='block lg:grid grid-cols-2 lg:mx-16'>
                        <LandingStats stat="&gt;1 MILLION" description="yd&sup3; remediated"/>
                        <LandingStats stat="&lt;12 MONTHS" description="Duration of active remediation"/>
                        <LandingStats stat="5 BILLION KG" description="Contaminants removed"/>
                        <LandingStats stat="18 PATENTS" description="Around the world"/>
                    </div>
                </div>
                {/* ET-DSP description large screen*/}
                <div className='mt-16 hidden lg:grid grid-cols-7 gap-24 mx-[3%]'>
                    <div className='col-span-4 border-r-2 border-b-2 border-[#407492] px-12 pt-20'>
                        <h1 className='text-xl calibri-400'>Electro-Thermal Dynamic Stripping Process</h1>
                        <h1 className='mt-8 lg:text-6xl md:text-5xl text-4xl calibri-500'>ET-DSP&trade;</h1>
                        <p className='text-justify mt-16'><span className='text-[#8ecfec]'>Discover ET-DSP&trade;:</span> Electro-Thermal Dynamic Stripping Process built upon unparalleled expertise in multi-physical processes. This patented technology represents a significant advancement in in-situ electro-thermal remediation systems, offering unparalleled reliability and effectiveness without unnecessary boasting. It involves the introduction of electrical current into the contaminated soil volume through custom electrodes which are strategically placed throughout a contaminated zone.</p>
                        <button className='flex hover:bg-[#758da8] text-xl mx-auto my-12 rounded-full border-4 border-white opacity-100 py-4 px-6' onClick={() => navigate('/ET_DSP')}>Discover ET-DSP&trade;</button>
                    </div>
                    <div className='col-span-3 flex mx-auto'>
                        <img className='my-auto' src={Electrode} alt="Electrode" />
                    </div>
                </div>
                {/* ET-DSP description small screen*/}
                <div className='mt-16 lg:hidden block mx-[3%]'>
                    <div className='border-r-2 border-t-2 border-[#407492] px-12 pt-20'>
                        <h1 className='text-xl calibri-400'>Electro-Thermal Dynamic Stripping Process</h1>
                        <h1 className='mt-8 lg:text-6xl md:text-5xl text-4xl calibri-500'>ET-DSP&trade;</h1>
                        <div className='my-24'>
                            <img src={Electrode} alt="Electrode" />
                        </div>
                        <p className='text-justify mt-16'><span className='text-[#8ecfec]'>Discover ET-DSP&trade;:</span> Electro-Thermal Dynamic Stripping Process built upon unparalleled expertise in multi-physical processes. This patented technology represents a significant advancement in in-situ electro-thermal remediation systems, offering unparalleled reliability and effectiveness without unnecessary boasting. It involves the introduction of electrical current into the contaminated soil volume through custom electrodes which are strategically placed throughout a contaminated zone.</p>
                        <button className='flex hover:bg-[#758da8] text-md mx-auto my-12 rounded-full border-4 border-white opacity-100 py-4 px-6' onClick={() => navigate('/ET_DSP')}>Discover ET-DSP&trade;</button>
                    </div>
                </div>
                {/* IT-DSP description large screen*/}
                <div className='mt-16 hidden lg:grid grid-cols-7 gap-24 mx-[3%]'>
                    <div className='col-span-3 flex justify-center'>
                        <img className='my-auto' src={ITDSPCoil} alt="ITDSP Coil" />
                    </div>
                    <div className='col-span-4 border-t-2 border-l-2 border-[#407492] px-12 pt-20'>
                        <h1 className='text-xl calibri-400'>Inductive-Thermal Dynamic Stripping Process</h1>
                        <h1 className='mt-8 lg:text-6xl md:text-5xl text-4xl calibri-500'>IT-DSP&trade;</h1>
                        <p className='text-justify mt-16'><span className='text-[#8ecfec]'>Discover IT-DSP&trade;:</span> Inductive Thermal-Dynamic Stripping Process, the latest breakthrough technology. Departing from conventional methods, inductive heating offers direct, rate-limiting radient heat transfer-free operation. The resilient coils, paired with computer-controlled high-frequency electromagnetic fields, deliver unparalleled energy density into large-diameter pipes. Never before has soil remediation seen such potent heating capabilities based on thermal conduction.</p>
                        <button className='flex hover:bg-[#758da8] text-xl mx-auto my-12 rounded-full border-4 border-white opacity-100 py-4 px-6' onClick={() => navigate('/IT_DSP')}>Discover IT-DSP&trade;</button>
                    </div>
                </div>
                {/* IT-DSP description small screen*/}
                <div className='mt-16 lg:hidden block mx-[3%]'>
                    <div className='border-l-2 border-t-2 border-[#407492] px-12 pt-20'>
                        <h1 className='text-xl calibri-400'>Inductive-Thermal Dynamic Stripping Process</h1>
                        <h1 className='mt-8 lg:text-6xl md:text-5xl text-4xl calibri-500'>IT-DSP&trade;</h1>
                        <div className='my-24'>
                            <img src={ITDSPCoil} alt="ITDSP Coil" />
                        </div>
                        <p className='text-justify mt-16'><span className='text-[#8ecfec]'>Discover IT-DSP&trade;:</span> Inductive Thermal-Dynamic Stripping Process, the latest breakthrough technology. Departing from conventional methods, inductive heating offers direct, rate-limiting radient heat transfer-free operation. The resilient coils, paired with computer-controlled high-frequency electromagnetic fields, deliver unparalleled energy density into large-diameter pipes. Never before has soil remediation seen such potent heating capabilities based on thermal conduction.</p>
                        <button className='flex hover:bg-[#758da8] text-md mx-auto my-12 rounded-full border-4 border-white opacity-100 py-4 px-6' onClick={() => navigate('/IT_DSP')}>Discover IT-DSP&trade;</button>
                    </div>
                </div>
                {/* Where we have worked large screen*/}
                <div className='mt-24 mx-[3%]'>
                    <h1 className='text-center lg:text-6xl text-4xl calibri-500'>Extracting contaminants worldwide.</h1>
                    <div className='flex justify-center'>
                        <img src={ProjectsMap} alt="Project Map" />
                    </div>
                </div>
            </div>
       )
}

export default LandingMain