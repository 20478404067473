import React from 'react'

const TeamCard = (props) => {
  return (
    <div className='xl:flex col-span-1'>
        <div>
            <img className='mx-auto rounded-lg xl:h-56 lg:min-w-56 xl:h-40 lg:min-w-40' src={props.img} alt={props.imgAlt} />
        </div>
        <div className='xl:text-left text-center xl:items-start items-center xl:text-left flex flex-col xl:my-0 my-12 pl-8 pb-12'>
            <div>
                <span className='flex xl:block'>
                    <h1 className='text-white calibri-300 text-2xl mr-2 xl:mr-0'>{ props.firstName }</h1>
                    <h1 className='text-white calibri-600 text-2xl'>{ props.lastName }</h1>
                </span>
                <p className='text-white calibri-300 text-md my-4'>{ props.education }</p>
                <hr className='xl:mx-0 mx-auto w-20 border-t border-[#535558]'/>
            </div>
            <div>
                <p className='text-white calibri-300 text-sm mt-4'>{ props.position }</p>
                <p className='text-white calibri-300 text-sm mt-4'>{ props.subPosition }</p>
            </div>
        </div>
    </div>
  )
}

export default TeamCard