import React, { useEffect } from 'react'
import Navbar from '../Navbar'
import Header from '../Header.jsx'
import CareersMain from './CareersMain.jsx'
import Footer from '../Footer'

const ContactUs = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <div>
        <div className='bg-fixed bg-center z-1 bg-white bg-gradient-to-r from-[#081d2b] via-[#163f6c] to-[#758da7]'>
            <Navbar />
            <Header title="CAREERS" bgImage="bg-[url('./assets/ContactUs/ContactUsBannerBackground.jpg')]" titleClasses="lg:text-6xl md:text-5xl text-4xl xl:pt-[26rem] lg:pt-72 pt-32 xl:pb-28 lg:pb-24 pb-12 xl:pl-24 lg:pl-20 pl-8 bg-gradient-to-t from-[#01111c]" subTitleClasses="hidden"/>
            <CareersMain />
            <Footer/>
        </div>
    </div>
  )
}

export default ContactUs