import React from 'react'
import ET_DSPDiagram from '../../assets/ET-DSP/ET-DSPDiagram.png'
import ET_DSPProcess from '../../assets/ET-DSP/ET_DSPProcess.png'
import ET_DSPWaterCirc from '../../assets/ET-DSP/ET-DSPWaterCirc.png'
import ET_DSPAdvantage from '../../assets/ET-DSP/ET_DSPAdvantage.png'
import ET_DSPAdvantagePic from '../../assets/ET-DSP/ET-DSPAdvantagePic.jpg'
import ElectrodeDiagram from '../../assets/ET-DSP/ElectrodeDiagram.png'


const ET_DSPMain = () => {
  return (
    <div className='text-white calibri-300'>
        <div>
            <h1 className='text-start text-white xl:text-5xl md:text-4xl text-2xl calibri-400 pt-24 pb-12 xl:mx-48 lg:mx-24 mx-12'>ENGINEERED TO CREATE <span className='text-[#8ac7e2]'>CLEANER, SAFER</span> ENVIRONMENTS.</h1>
        </div>
        <div className='xl:grid xl:grid-cols-2 xl:px-48 lg:px-24 px-12 bg-gradient-to-t from-[#0c112a]'>
            <div className='col-span-1 my-auto'>
                <p className='lg:text-lg text-md'>Electro-Thermal Dynamic Stripping Process&trade;</p>
                <p className='lg:text-lg text-md text-[#8ac7e2] mb-12'><i>In-situ thermal remediation technology.</i></p>
                <p className='text-justify lg:text-lg text-md mb-12'>Like all extraordinary innovations, ET-DSP&trade; emerged from an ambitious concept firmly rooted in advanced engineering principles. This original technology reples on the controlled application of <span className='text-[#8ac7e2]'>electrical currents</span> and <span className='text-[#8ac7e2]'>thermal energy</span> to facilitate the removal of harmful contaminants from soil and groundwater.</p>
                <p className='calibri-400 lg:text-lg text-md text-[#8ac7e2]'>Revolutionize Environmental Remediation.</p>
            </div>
            <div className='col-span-1 flex justify-center xl:pt-0 xl:pt-8'>
                <img className='xl:h-4/5 h-2/5' src={ET_DSPDiagram} alt='Electrode' />
            </div>
        </div>
        <div className='xl:grid xl:grid-cols-2 xl:gap-24 xl:px-48 lg:px-24 px-12 pt-16 bg-[#0c112a]'>
            <div className='col-span-1'>
                <h1 className='text-[#7fbbd7] lg:text-5xl md:text-4xl text-3xl calibri-500 pb-16'>THE PROCESS</h1>
                <p className='text-justify lg:text-lg text-md mb-8'><span className='text-[#7fbbd7]'>Electrodes</span> are strategically inserted into the contaminated area, and an <span className='text-[#7fbbd7]'>electric current</span> is passed through them, heating the soil or groundwater. This thermal process effectively volatilizes contaminants, facilitating their <span className='text-[#7fbbd7]'>desorption from soil particles</span> and their removal from groundwater.</p>
                <p className='text-justify lg:text-lg text-md mb-8'>The volatilized contaminants are then captured through a <span className='text-[#7fbbd7]'>vapor extraction system</span>, where they are treated using advanced methods such as carbon adsorption thermal oxidation, or other suitable technologies to ensure their removal.</p>
                <p className='text-justify lg:text-lg text-md mb-8'>Throughout the remediation process, project engineers maintain precise control over each electrode through a <span className='text-[#7fbbd7]'>real-time monitoring system</span> accessible via a live website. This platfrom allows clients to track temperature and heat parameters, ensuring that project objectives are met within the designated timeframe.</p>
            </div>
            <div className='col-span-1 flex justify-center xl:mt-0 mt-8'>
                <img className='xl:h-4/5 h-2/5' src={ET_DSPProcess} alt='ET-DSP Process' />
            </div>
        </div>
        <div className='xl:grid xl:grid-cols-11 xl:gap-12 xl:px-48 lg:px-24 px-12 xl:pb-16 pb-12 bg-gradient-to-b from-[#0c112a]'>
            <div className='col-span-5 py-auto'>
                <h1 className='text-left text-white lg:text-5xl md:text-4xl text-3xl calibri-500 py-12'>COMPONENTS</h1>
                <p className='text-justify lg:text-lg text-md mb-8'>McMillan-McGee designs and manufactures four primary specialty components integral to the ET-DSP&trade; system: the <span className='text-[#7fbbd7]'>Power Delivery System (PDS), Electrodes, Temperature Sensors, and the Water Circulation System.</span></p>
            </div>
            <hr className='xl:block hidden col-span-1 flex mx-auto my-auto vertical-line'/>
            <hr className='xl:hidden block w-3/5 mx-auto'/>
            <div className='col-span-5 flex justify-center xl:mt-0 mt-8'>
                <img className='h-full' src={ET_DSPWaterCirc} alt='ET-DSP Water Circulation' />
            </div>
        </div>
        <div className='bg-gradient-to-t from-[#0c112a]'>
            <h1 className='text-white lg:text-4xl md:text-3xl text-2xl calibri-500 xl:px-56 px-36 pt-8'>ET-DSP&trade; ELECTRODES</h1>
            <div className='xl:grid xl:grid-cols-3 xl:gap-16 xl:px-48 lg:px-24 px-12 pb-12'>
                <div className='col-span-2 flex justify-center xl:mt-0'>
                    <img className='object-contain min-w-fixed' src={ElectrodeDiagram} alt='ET-DSP Electrode Diagram' />
                </div>
                <div className='col-span-1'>
                    <h1 className='text-[#7fbbd7] lg:text-5xl md:text-4xl text-3xl calibri-500 mx-auto my-12'>Patented and Proven.</h1>
                    <p className='text-justify lg:text-lg text-md pb-8'>Our custom electrodes efficiently heat contaminated plumes of any shape, depth, and size. During ET-DSP&trade; operations, water injection at the electrodes increase convective heat transfer, spreading heat fruhter into the subsurface. Combining <span className='text-[#7fbbd7]'>electrical, conductive, and convective</span> heating methods achieves superior results. This approach enhances both efficiency and effectiveness in remediating contaminated areas.</p>
                </div>  
            </div>
        </div>
        <div className='bg-gradient-to-b from-[#0c112a]'>
            <div className='xl:grid xl:grid-cols-2 xl:gap-24 xl:px-48 lg:px-24 px-12 xl:py-16 pb-12'>
                <div className='col-span-1'>
                    <img className='xl:mx-0 rounded-xl' src={ET_DSPAdvantagePic} alt="ET-DSP Advantage" />
                </div>
                <div className='col-span-1 xl:pt-0 pt-16'>
                    <h1 className='text-white md:text-5xl text-3xl calibri-500 xl:mb-12 mb-8'>ET-DSP&trade; ADVANTAGE</h1>
                    <img className='rounded-xl' src={ET_DSPAdvantage} alt='ET-DSP Advantage' />
                </div> 
            </div>
        </div>
    </div>
  )
}

export default ET_DSPMain