import React from 'react'
// import PitchBookSquare from '../assets/SocialMediaLogos/PitchBook_logo.png'
// import FaceBookSquare from '../assets/SocialMediaLogos/FaceBook_logo.png'
import Instagram from '../assets/SocialMediaLogos/Instagram_logo.png'
import LinkedIn from '../assets/SocialMediaLogos/LinkedIn_logo.png'
import Twitter from '../assets/SocialMediaLogos/Twitter_logo.png'
// import IndeedSquare from '../assets/SocialMediaLogos/Indeed_logo.jpeg'

const Footer = () => {
  return (
    <div className='bg-gradient-to-t from-black px-16 px-4 text-gray-300'>
            <hr className='mb-8'/>
            <div className="mx-[5%] block lg:grid lg:grid-cols-3 lg:gap-4">
                <div className='hidden lg:block lg:col-span-1'>
                    <h1 className='font-semibold mb-4'>LOCATIONS</h1>
                    <p className='text-xs font-semibold'>McMILLAN-McGEE HEADQUARTERS:</p>
                    <p className='text-xs mb-4'>4895 35B ST SE, CALGARY, AB T2B 3M9, CANADA</p>

                    <p className='text-xs font-semibold'>McMILLAN-McGEE USA/MC&sup2; USA</p>
                    <p className='text-xs mb-4'>391B TRANSPOINT DR, DUPO, IL 62239, UNITED STATES</p>

                    <p className='text-xs font-semibold'>McMILLAN-McGEE EUROPE/EMc²</p>
                    <p className='text-xs mb-4'>ZELZATE, FLANDERS, 9060, BELGIUM</p>
                </div>
                <div className='hidden lg:block lg:col-span-1'>
                    <p className='text-xs font-semibold mt-9'>McMILLAN-McGEE BEIJING:</p>
                    <p className='text-xs mb-4'>DONGCHENG DISTRICT, BEIJING, CHINA</p>

                    <p className='text-xs font-semibold'>McMILLAN-McGEE SHANGHAI/CLEANSOIL</p>
                    <p className='text-xs mb-4'>TO BE DETERMINED</p>

                    <p className='text-xs font-semibold'>McMILLAN-McGEE BRAZIL/RMc²</p>
                    <p className='text-xs'>R.ETTORE SOLIANI, 338-DISTRITO INDUSTRIAL NOVA ERA,</p>
                    <p className='text-xs mb-4'>INDAIATUBA - SP, 13347-394, BRAZIL</p>
                </div>
                <div>
                    <h1 className='font-semibold mb-4'>CONTACT</h1>
                    <p className='text-xs'>INFO@MCMILLAN-MCGEE.COM</p>
                    <p className='text-xs'>+1 403 569 5100</p>
                    <p className='text-xs'>+1 403 272 7201 (FAX)</p>
                    <div className='flex md:w-[75%] my-6'>
                        <a href="https://www.linkedin.com/company/mcmillan-mcgee-corporation/?originalSubdomain=ca" target="_blank" rel="noopener noreferrer" className='mr-4 size-[2rem]'>
                            <button className="w-8 h-8 bg-cover bg-no-repeat" style={{ backgroundImage: `url(${LinkedIn})` }} />
                        </a>
                        <a href="https://twitter.com/mc2_tech" target="_blank" rel="noopener noreferrer" className='mx-4 size-[2rem]'>
                            <button className="w-8 h-8 bg-cover bg-no-repeat" style={{ backgroundImage: `url(${Twitter})` }} />
                        </a>
                        <a href="https://www.instagram.com/mcmillan_mcgee/" target="_blank" rel="noopener noreferrer" className='mx-4 size-[2rem]'>
                            <button className="w-8 h-8 bg-cover bg-no-repeat" style={{ backgroundImage: `url(${Instagram})` }} />
                        </a>
                        {/* <a href="https://www.google.com/search?q=mcmillan+mcgee&oq=mcm&aqs=chrome.2.69i60j46i39i175i199i512i650i654j69i59j46i67i199i433i457i465i512i650j69i60l4.4559j0j7&sourceid=chrome&ie=UTF-8#ip=1:~:text=McMillan%20McGee%20Corp,Mcmillan%2DMcgee%2DCorp" target="_blank" rel="noopener noreferrer" className='size-[2rem]'>
                            <button className="w-8 h-8 bg-cover bg-no-repeat" style={{ backgroundImage: `url(${IndeedSquare})` }} />
                        </a>
                        <a href="https://www.facebook.com/p/McMillan-McGee-Corp-100066666978043/?paipv=0&eav=AfaGg5shy9zM20g4X0L31k5E67nf2MuL5GhdhETF0HdWzmZcwBDubZbUm4eUF4PjYik&_rdr" target="_blank" rel="noopener noreferrer" className='size-[2rem]'>
                            <button className="w-8 h-8 bg-cover bg-no-repeat" style={{ backgroundImage: `url(${FaceBookSquare})` }} />
                        </a>
                        <a href="https://pitchbook.com/profiles/company/149514-85#overview" target="_blank" rel="noopener noreferrer" className='size-[2rem]'>
                            <button className="w-8 h-8 bg-cover bg-no-repeat" style={{ backgroundImage: `url(${PitchBookSquare})` }} />
                        </a> */}
                    </div>
                </div>
            </div>
            <hr className='my-8'/>
            <p className='text-center text-xs'>© 2024 MCMILLAN-MCGEE CORP. ALL RIGHTS RESERVED. DESIGNED IN CALGARY, ALBERTA</p>
    </div>
  )
}

export default Footer