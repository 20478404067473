import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './components/Main Pages/MainPage.jsx'
import AboutUs from './components/About Us/AboutUs.jsx'
import ContactUs from './components/Contact Us/ContactUs.jsx'
import Careers from './components/Careers/Careers.jsx'
import Team from './components/Team/Team.jsx'
import ET_DSP from './components/ET_DSP/ET_DSP.jsx';
import IT_DSP from './components/IT_DSP/IT_DSP.jsx';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<MainPage />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Careers" element={<Careers />} />
        <Route path="/Team" element={<Team />} />
        <Route path="/ET_DSP" element={<ET_DSP />} />
        <Route path="/IT_DSP" element={<IT_DSP />} />
      </Routes>
    </Router>
  );
}

export default App;
