import React from 'react';
import Navbar from '../Navbar.jsx';
import LandingBanner from './LandingBanner.jsx';
import LandingMain from './LandingMain.jsx';
// import AffiliationLogos from './AffiliationLogos.jsx';
import Footer from '../Footer.jsx';

const MainPage = () => {
    return (
        <div>
            <div 
                className='z-1 bg-cover'
                style={{
                    backgroundImage: `url(${require('../../assets/LandingMainBackground.jpg')})`,
                    backgroundAttachment: `fixed`,
                }}
            >
            <Navbar />
            <LandingBanner />
            <LandingMain />
            {/* May not be able to show these legally */}
            {/* <AffiliationLogos/> */}
            <Footer/>
            </div>
        </div>
    );
};

export default MainPage;