import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Mc2_Logo from '../assets/Mc2_Logo.png'

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);

    const companyButtonRef = useRef(null);
    const companyDropdownRef = useRef(null);
    const servicesButtonRef = useRef(null);
    const servicesDropdownRef = useRef(null);
    const resourcesButtonRef = useRef(null);
    const resourcesDropdownRef = useRef(null);
    const contactButtonRef = useRef(null);
    const contactDropdownRef = useRef(null);
    const navigate = useNavigate();

    const handleNav = () => {
        setNav(!nav);
    };

    const handleToggle = (dropdown) => {
        setOpenDropdown(prevDropdown => (prevDropdown === dropdown ? null : dropdown));
    };

    const handleClickOutside = (event) => {
        if (companyDropdownRef.current && !companyDropdownRef.current.contains(event.target) &&
            companyButtonRef.current && !companyButtonRef.current.contains(event.target) &&
            servicesDropdownRef.current && !servicesDropdownRef.current.contains(event.target) &&
            servicesButtonRef.current && !servicesButtonRef.current.contains(event.target) &&
            resourcesDropdownRef.current && !resourcesDropdownRef.current.contains(event.target) &&
            resourcesButtonRef.current && !resourcesButtonRef.current.contains(event.target) &&
            contactDropdownRef.current && !contactDropdownRef.current.contains(event.target) &&
            contactButtonRef.current && !contactButtonRef.current.contains(event.target)) {
            setOpenDropdown(null);
        }
    };

    const handleBlur = (dropdown, ref) => {
        setTimeout(() => {
            if (ref.current && !ref.current.contains(document.activeElement)) {
                setOpenDropdown(prevDropdown => (prevDropdown === dropdown ? null : prevDropdown));
            }
        }, 100); // Timeout to allow click event to register
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className='flex fixed w-full pr-4 top-0 left-0 items-center h-18 mx-auto justify-around bg-gradient-to-r from-[#122a46] to-[#242527] text-white border-2 border-[#616466] z-50'>
            <button className='h-full w-full' onClick={() => navigate('/')}><img className='max-w-28 pt-2 px-4 font-bold text-[#6197fa]' src={Mc2_Logo} alt="Mc2 Logo" /></button>
            <div>
                <ul className='hidden xl:flex'>
                    <hr className="border-r border-[#28389c] h-16 my-auto"></hr>
                    <li className='calibri-300 text-xl whitespace-nowrap'>
                        <button 
                            className='h-18 w-48 hover:bg-[#273e5c]'
                            ref={companyButtonRef}
                            onClick={() => handleToggle('company')}
                            onBlur={() => handleBlur('company', companyDropdownRef)}
                        >
                            COMPANY
                        </button>
                        {openDropdown === 'company' && (
                            <div ref={companyDropdownRef} className="absolute w-48 dropdown-menu">
                                <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100" onMouseDown={() => navigate('/AboutUs')}>About Us</button>
                                <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100" onMouseDown={() => navigate('/Team')}>Our Team</button>
                                {/* <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">Projects</button> */}
                            </div>
                        )}
                    </li>
                    <hr className="border-r border-[#28389c] h-16 my-auto"></hr>
                    <li className='calibri-300 text-xl whitespace-nowrap'>
                        <button 
                            className='h-18 w-48 hover:bg-[#273e5c]'
                            ref={servicesButtonRef}
                            onClick={() => handleToggle('services')}
                            onBlur={() => handleBlur('services', servicesDropdownRef)}
                        >
                            SERVICES
                        </button>
                        {openDropdown === 'services' && (
                            <div ref={servicesDropdownRef} className="absolute w-48 dropdown-menu">
                                <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100" onMouseDown={() => navigate('/ET_DSP')}>ET-DSP&trade;</button>
                                <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100" onMouseDown={() => navigate('/IT_DSP')}>IT-DSP&trade;</button>

                                {/* <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">In-situ thermal Technology</button> */}
                                {/* <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">Live Monitering</button> */}
                                {/* <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">Laboratory Services</button> */}
                                {/* <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">Manufacturing</button> */}
                            </div>
                        )}
                    </li>
                    {/* <hr className="border-r border-[#28389c] h-16 my-auto"></hr>
                    <li className='calibri-300 text-xl whitespace-nowrap'>
                        <button 
                            className='h-18 w-48 hover:bg-[#273e5c]'
                            ref={resourcesButtonRef}
                            onClick={() => handleToggle('resources')}
                            onBlur={() => handleBlur('resources', resourcesDropdownRef)}
                        >
                            RESOURCES
                        </button>
                        {openDropdown === 'resources' && (
                            <div ref={resourcesDropdownRef} className="absolute w-96 dropdown-menu">
                                <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">Awards and Certifications</button>
                                <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">Publications and Technical Papers</button>
                                <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">Patents</button>
                                <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">Media</button>
                                <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">Press Release</button>
                            </div>
                        )}
                    </li> */}
                    <hr className="border-r border-[#28389c] h-16 my-auto"></hr>
                    <li className='calibri-300 text-xl whitespace-nowrap'>
                        <button 
                            className='h-18 w-48 hover:bg-[#273e5c]'
                            ref={contactButtonRef}
                            onClick={() => handleToggle('contact')}
                            onBlur={() => handleBlur('contact', contactDropdownRef)}
                        >
                            CONTACT
                        </button>
                        {openDropdown === 'contact' && (
                            <div ref={contactDropdownRef} className="absolute w-48 dropdown-menu">
                                <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100" onMouseDown={() => navigate('/ContactUs')}>Get in Touch</button>
                                <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100" onMouseDown={() => navigate('/Careers')}>Careers</button>
                            </div>
                        )}
                    </li>
                    <hr className="border-r border-[#28389c] h-16 my-auto"></hr>
                    {/* <li className='calibri-300 text-xl whitespace-nowrap my-auto'>
                        <button className="bg-white hover:bg-[#ccf1fc] text-[#223f8d] rounded-md font-medium mx-12 p-4 calibri-500" onClick={handleShowContactForm}>
                            LET'S CONNECT
                        </button>
                        {isContactFormVisible && 
                            <ContactUsForm 
                                onClose={handleCloseContactForm} 
                                extraClasses={{
                                    isPopUp: 'fixed inset-0 overflow-y-auto flex items-center justify-center bg-black bg-opacity-50 h-full',
                                    isClosable: true,
                                    formStyling: 'w-3/4'
                                }}
                            />
                        }
                    </li> */}
                </ul>
            </div>
            <div>
                <div onClick={handleNav} className='xl:hidden flex flex-row items-center justify-between'>
                    {nav ? <AiOutlineClose className='mt-2' size={30}/> : <AiOutlineMenu className='mt-2' size={30}/>}
                </div>
                <ul className={nav ? 'xl:hidden fixed bg-gradient-to-r from-[#122a46] to-[#242527] left-0 top-0 max-w-60 w-[40%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
                    <img className='max-w-60 w-[70%] pt-2 px-4 font-bold text-[#6197fa' src={Mc2_Logo} alt="Mc2 Logo" />
                    <li className='text-xl whitespace-nowrap mt-8'>
                        <button 
                            className='h-18 w-full hover:bg-[#273e5c]'
                            ref={companyButtonRef}
                            onClick={() => handleToggle('company')}
                            onBlur={() => handleBlur('company', companyDropdownRef)}
                        >
                            COMPANY
                        </button>
                        {openDropdown === 'company' && (
                            <div ref={companyDropdownRef} className="w-full dropdown-menu">
                                <button className="block w-full bg-[#1b2737] hover:bg-[#273e5c] px-4 py-3 text-sm opacity-90" onClick={() => navigate('/AboutUs')}>About Us</button>
                                <button className="block w-full bg-[#1b2737] hover:bg-[#273e5c] px-4 py-3 text-sm opacity-90" onClick={() => navigate('/Team')}>Our Team</button>
                                {/* <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">Projects</button> */}
                            </div>
                        )}
                    </li>
                    <li className='text-xl whitespace-nowrap'>
                    <button 
                            className='h-18 w-full hover:bg-[#273e5c]'
                            ref={servicesButtonRef}
                            onClick={() => handleToggle('services')}
                            onBlur={() => handleBlur('services', servicesDropdownRef)}
                        >
                            SERVICES
                        </button>
                        {openDropdown === 'services' && (
                            <div ref={servicesDropdownRef} className="w-full dropdown-menu">
                                <button className="block w-full bg-[#1b2737] hover:bg-[#273e5c] px-4 py-3 text-sm opacity-90" onClick={() => navigate('/ET_DSP')}>ET-DSP&trade;</button>
                                <button className="block w-full bg-[#1b2737] hover:bg-[#273e5c] px-4 py-3 text-sm opacity-90" onClick={() => navigate('/IT_DSP')}>IT-DSP&trade;</button>

                                {/* <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">In-situ thermal Technology</button> */}
                                {/* <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">Live Monitering</button> */}
                                {/* <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">Laboratory Services</button> */}
                                {/* <button className="block bg-[#1b2737] px-4 py-4 text-sm w-full text-left text-xl opacity-90 hover:opacity-100">Manufacturing</button> */}
                            </div>
                        )}
                    </li>
                    {/* <li className='p-4 text-xl whitespace-nowrap'>Resources</li> */}
                    <li className='text-xl whitespace-nowrap'>
                        <button 
                            className='h-18 w-full hover:bg-[#273e5c]'
                            ref={contactButtonRef}
                            onClick={() => handleToggle('contact')}
                            onBlur={() => handleBlur('contact', contactDropdownRef)}
                        >
                            CONTACT
                        </button>
                        {openDropdown === 'contact' && (
                            <div ref={contactDropdownRef} className="w-full dropdown-menu">
                                <button className="block w-full bg-[#1b2737] hover:bg-[#273e5c] px-4 py-3 text-sm opacity-90" onClick={() => navigate('/ContactUs')}>Get in Touch</button>
                                <button className="block w-full bg-[#1b2737] hover:bg-[#273e5c] px-4 py-3 text-sm opacity-90" onClick={() => navigate('/Careers')}>Careers</button>
                            </div>
                        )}
                    </li>
                    {/* <li className='whitespace-nowrap flex justify-center'>
                        <button className="bg-white hover:bg-[#ccf1fc] text-[#223f8d] max-w-60 w-[80%] rounded-md font-medium my-8 mx-4 py-3 calibri-500" onClick={handleShowContactForm}>
                            LET'S CONNECT
                        </button>
                        {isContactFormVisible && 
                            <ContactUsForm 
                                onClose={handleCloseContactForm}
                                extraClasses={{
                                    isPopUp: 'fixed inset-0 overflow-y-auto flex justify-center bg-black bg-opacity-50 h-full',
                                    isClosable: true,
                                    formStyling: 'w-11/12'
                                }}
                            />
                        }
                    </li> */}
                </ul>
            </div>   
        </div>
    );
};

export default Navbar;
