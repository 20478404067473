import React from 'react'
import AboutUsWorking from '../../assets/AboutUs/AboutUsWorking.png'
import AboutUsDoTheMath from '../../assets/AboutUs/AboutUsDoTheMath.png'
import AboutUsQualityManufacturing from '../../assets/AboutUs/AboutUsQualityManufacturing.png'
import AboutUsPragAndTran from '../../assets/AboutUs/AboutUsPragAndTran.png'
import AboutUsInnovation from '../../assets/AboutUs/AboutUsInnovation.png'
import { useNavigate } from 'react-router-dom';

const AboutUsMain = () => {
  const navigate = useNavigate();

  return (
    <div className='calibri-300'>
        <div className='block xl:grid xl:grid-cols-4'>
            {/* Page introduction text*/}
            <div className='col-span-3 mt-24 mx-12 xl:mx-24'>
                <h1 className='text-white mb-16 calibri-600 lg:text-5xl md:text-3xl text-md'><span className='whitespace-normal md:whitespace-nowrap'>PLUG INTO THE RAPIDLY GROWING</span> <br/><span className='whitespace-nowrap'>REMEDIATION INDUSTRY.</span></h1>
                <p className='text-justify text-white my-12 lg:text-lg md:text-md sm:text-sm'>McMillan-Mcgee (Mc&#178;) is a technology company and the global leader in providing electromagnetic systems and engineering services to thermal remediation and energy projects around the world. Since 1991, we have led the way in Electrical Resistive Heating (ERH) projects within the energy and environmental sectors, specializing in advanced solutions for remedying contaminated soil and groundwater. Mc&#178; provides operation, optimization, maintenance, and project management for all thermal projects to ensure proper site selection, installation and safety.</p>
                <p className='text-justify text-white lg:text-lg md:text-md sm:text-sm'>At Mc&#178; we plan, develop and manage complex thermal remediation solutions for clients who desire speed, performance and proven results. Our adaptive strategy integrates deep expertise and specialized tools to effectively address even the most challenging environmental conditions. With technology board is comprised of the world's leading experts in applied electromagnetic and electrical heating of porus media, we have successfully remediated over 150 contaminated sites, including the largest in-situ thermal remediation project globally. Furthermore, we are committed to continuously refining our approaches to minimize ecological impact while delivering technologically advanced solutions.</p>
            </div>
            <div className='col-span-1 xl:mt-24 mt-12 xl:mt-0 flex items-center justify-center'>
                <button className='w-60 p-4 rounded-xl hover:bg-[#758da8] border-4 border-[#8ccae8] calibri-500 lg:text-lg md:text-md sm:text-sm text-white' onClick={() => navigate('/Team')}>MEET OUR TEAM</button>
            </div>
        </div>
        {/* Transition picture of people working */}
        <div className='xl:mt-24 mt-12'>
            <img src={AboutUsWorking} alt="Our team Working" className="w-full h-auto" />
        </div>
        {/* Science focused and future driven */}
        <div className='block xl:grid xl:grid-cols-5 xl:mt-24 mt-12 xl:mx-24 mx-12'>
            <div className='col-span-1' />
            <div className='col-span-4'>
                <h1 className='text-white mb-16 calibri-600 lg:text-5xl text-md whitespace-normal md:whitespace-nowrap'>SCIENCE FOCUSED AND FUTURE DRIVEN.</h1>
                <p className='text-justify text-white my-12 lg:text-lg md:text-md sm:text-sm'>Mc&#178; is committed to transparency, responsibility, and sustainability across all aspects of our operations. Our Environmental, Social, and Governance (ESG) form the bedrock of our corporate culture, guiding our decisions and actions as we strive to make a positive impact on the environment, society, and governance standards.</p>
                <p className='text-justify text-white my-12 lg:text-lg md:text-md sm:text-sm'>In line with our commitment to sustainability, we actively work to minimize our environmental footprint across all operations. Through innovation and strategic planning, we develop solutions that are both environmentally friendly and technologically advanced. At Mc&#178;, we advocate for an inclusive environment where the contributions of every team member are valued and respected. We recognize that diverse perspectives are essential for driving scientific innovation and achieving our future-driven goals.</p>
                <p className='text-justify text-[#83c2e0] calibri-500 my-12 lg:text-lg md:text-md sm:text-sm'>"We must not only learn to tolerate our differences. We must welcome them as the richness and diversity which can lead to true intelligence." - ALBERT EINSTEIN</p>
            </div>
        </div>
        <hr className='w-[80%] mx-auto xl:my-12 my-4 bg-[#1e4c78]'/>
        {/* Do The Math section */}
        <div className='flex flex-col-reverse xl:grid xl:grid-cols-9 xl:mt-24 mt-12 xl:mx-24 mx-12'>
            <div className='col-span-4'>
                <img src={AboutUsDoTheMath} alt="Our team Working" className="w-full h-auto" />
            </div>
            <div className='col-span-1'/>
            <div className='col-span-4 xl:mt-24 mt-12'>
                <h1 className='text-white mb-16 calibri-600 lg:text-5xl text-4xl'>DO THE MATH&trade;</h1>
                <p className='text-justify text-white my-12 lg:text-lg md:text-md sm:text-sm'>Focused on efficiency and reliability, we prevent delays by getting it right the first time. Our precise designs rely on advanced mathematical modeling for clean and safe project outcomes. With expertise in Chemical, Electrical, Environmental, Mechanical, and Civil Engineering, our versatile team collaborates to develop customized strategies and plans to achieve remediation objectives.</p>
            </div>
        </div>
        {/* Quality Manufacturing section */}
        <div className='block xl:grid xl:grid-cols-9 mt-12 xl:mx-24 mx-12'>
            <div className='col-span-4 xl:mt-24 mt-12'>
                <h1 className='text-white mb-16 calibri-600 lg:text-5xl text-4xl'>QUALITY MANUFACTURING</h1>
                <p className='text-justify text-white my-12 lg:text-lg md:text-md sm:text-sm'>Our commitment to quality begins with our in-house manufacturing process, where our specialized team meticulously oversees every aspect of production. From ET-DSP&trade; electrodes to live monitoring systems, we maintain stringent standards at every step. This hands-on approach ensures that our equipment meets the highest reliability and customization standards, delivering top-tier performance to our clients.</p>
            </div> 
            <div className='col-span-1'/>
            <div className='col-span-4'>
                <img src={AboutUsQualityManufacturing} alt="Our team Working" className="w-full h-auto" />
            </div>
        </div>
        {/* Pragmatic and Transparent section */}
        <div className='flex flex-col-reverse xl:grid xl:grid-cols-9 mt-12 xl:mx-24 mx-12'>
            <div className='col-span-4'>
                <img src={AboutUsPragAndTran} alt="Our team Working" className="w-full h-auto" />
            </div>
            <div className='col-span-1'/>
            <div className='col-span-4 xl:mt-24 mt-12'>
                <h1 className='text-white mb-16 calibri-600 lg:text-5xl text-4xl'>PRAGMATIC AND TRANSPARENT</h1>
                <p className='text-justify text-white my-12 lg:text-lg md:text-md sm:text-sm'>Our pragmatic and transparent approach not only ensures successful project outcomes, but also adeptly minimizes risks and quickly resolves challenges. With a proven track record of over 200,000 incident-free work hours, safety remains our top priority, offering our clients reassurance throughout the project lifecycle. This dedication facilitates strong client relationships, with many established companies continuing to trust us over the years.</p>
            </div>
        </div>
        {/* Innovation section */}
        <div className='xl:grid xl:grid-cols-9 my-12 xl:mx-24 mx-12'>
            <div className='col-span-4 xl:mt-24 mt-12'>
                <h1 className='text-white mb-16 calibri-600 lg:text-5xl text-4xl'>INNOVATION</h1>
                <p className='text-justify text-white my-12 lg:text-lg md:text-md sm:text-sm'>Our legacy thrives on groundbreaking technologies. As industry leaders, we continually set and raise performance standards. With a dedicated R&D team, we refine our innovations, staying ahead in technological advancement. We drive ongoing progress in thermal remediation by collaborating with top universities. Guided by our unwavering commitment, we lead environmental remediation forward.</p>
            </div>
            <div className='col-span-1'/>
            <div className='col-span-4'>
                <img src={AboutUsInnovation} alt="Our team Working" className="w-full h-auto" />
            </div>
        </div>
    </div>
  )
}

export default AboutUsMain